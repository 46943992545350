import React from 'react';
import Layout from '@gaia/Layout';
import SEO from '../components/seo';
import { Typography, Box, Button, Stack, Breadcrumbs } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import { encodeActivismGroupURL } from '../utils/link-utils';
import { ChevronRight, NavigateNext } from '@mui/icons-material';

const ActivismGroupTemplate = ({ pageContext: { group } }) => {
    const url =
        typeof window !== 'undefined'
            ? window.location.href
            : `https://www.earthhero.org${encodeActivismGroupURL(group.organization)}`;

    return (
        <Layout>
            <SEO title={group['Organization']} url={url} />
            <Stack alignItems="center" justifyContent="center" sx={{ gap: { sm: 4 } }}>
                <Typography textAlign="center" variant="h1" sx={{ mb: { xs: 2, sm: 4 } }}>
                    {group['Organization']}
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNext />}
                    aria-label="breadcrumb"
                    sx={{ alignSelf: 'flex-start' }}
                >
                    <Link sx={{ color: 'text.primary' }} to="/activism-groups">
                        Activism Groups
                    </Link>
                    <Typography>{group['Organization']}</Typography>
                </Breadcrumbs>
                <Box
                    component="img"
                    src={group['Image']}
                    alt={`${group['Organization']} Icon`}
                    width="80%"
                    sx={{ marginBottom: { xs: 2, sm: 4 } }}
                />
                <Typography
                    paragraph
                    variant="body1"
                    sx={{ mb: { xs: 2, sm: 4 }, whiteSpace: 'pre-wrap' }}
                >
                    {group['Detailed description']}
                </Typography>
                <Button
                    href={group['Website']}
                    target="blank"
                    variant="outlined"
                    color="primary"
                    sx={{ size: { xs: 'medium', sm: 'large' } }}
                    endIcon={<ChevronRight />}
                >
                    Go to website
                </Button>
            </Stack>
        </Layout>
    );
};

export default ActivismGroupTemplate;
